import { Component } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { ActivatedRoute, UrlSegment } from '@angular/router';
import { LangueService } from '../services/langue.service';


@Component({
  selector: 'app-quotation',
  templateUrl: './quotation.component.html',
  styleUrls: ['./quotation.component.css']
})
export class QuotationComponent  {

    constructor(private meta: Meta,private route: ActivatedRoute,private langueService: LangueService ) {
      this.meta.addTags([
        {name: 'description', lang: 'en', content: 'hotel quotation'},
        {name: 'description', lang: 'fr', content: 'devis hotel'},
        {name: 'description', lang: 'es', content: 'cotizacion de hoteles'},
        {name: 'keywords', lang: 'en', content: 'hotel quotation,hotel quotes,quote hotel,hotel quote,hotel proposal,quotation for hotel booking,quotation for hotel rooms,hotel booking quotes'},
        {name: 'keywords', lang: 'fr', content: 'devis hotel'},
        {name: 'keywords', lang: 'es', content: 'cotizacion de hoteles,presupuesto de un hotel'},
        {name: 'charset', content: 'utf-8'},
        {name: 'viewport', content: 'width=device-width, initial-scale=1'},
      ]);

      this.langueService.setLangueCouranteSelonRoute(route.snapshot.url[0].path);
    }

}
