import { Component } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { ActivatedRoute, UrlSegment } from '@angular/router';
import { LangueService } from '../services/langue.service';


@Component({
  selector: 'app-sponsorship',
  templateUrl: './sponsorship.component.html',
  styleUrls: ['./sponsorship.component.css']
})
export class SponsorshipComponent {

  constructor(private meta: Meta,private route: ActivatedRoute,private langueService: LangueService ) {
    this.meta.addTags([
      {name: 'description', lang: 'en', content: 'Sponsorship Hotel Invoice'},
      {name: 'description', lang: 'fr', content: 'Parrainage Hotel Invoice'},
      {name: 'description', lang: 'es', content: 'Patrocinio Hotel Invoice'},
      {name: 'keywords', lang: 'en', content: 'Sponsorship Hotel Invoice'},
      {name: 'keywords', lang: 'fr', content: 'Parrainage Hotel Invoice'},
      {name: 'keywords', lang: 'es', content: 'Patrocinio Hotel Invoice'},
      {name: 'charset', content: 'utf-8'},
      {name: 'viewport', content: 'width=device-width, initial-scale=1'},
    ]);

    this.langueService.setLangueCouranteSelonRoute(route.snapshot.url[0].path);
  }

}
