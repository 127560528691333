import { Component } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { WOW } from 'wowjs/dist/wow.min';
import { ActivatedRoute, UrlSegment } from '@angular/router';
import { LangueService } from '../services/langue.service';


@Component({
  selector: 'app-billing-app',
  templateUrl: './billing-app.component.html',
  styleUrls: ['./billing-app.component.css']
})
export class BillingAppComponent {

 constructor(private meta: Meta,private route: ActivatedRoute,private langueService: LangueService ) {

      this.meta.addTags([
        {name: 'description', lang: 'en', content: 'hotel billing app'},
        {name: 'description', lang: 'fr', content: 'application android hotel'},
        {name: 'description', lang: 'es', content: 'app para hotel'},
        {name: 'keywords', lang: 'en', content: 'hotel billing app,hotel management apps,hotel app,hotel application,Hotel Invoice'},
        {name: 'keywords', lang: 'fr', content: 'application android hotel,application mobile pour hotel,application mobile hotel,Hotel Invoice'},
        {name: 'keywords', lang: 'es', content: 'app para hotel,aplicaciones para hoteles,tpv android, app para hoteles,app hoteles,app de hoteles,aplicacion de hoteles,aplicacion hoteles,app hotel,Hotel Invoice'},
        {name: 'charset', content: 'utf-8'},
        {name: 'viewport', content: 'width=device-width, initial-scale=1'},
      ]);

      if (route.snapshot.url[0] && route.snapshot.url[0].path){
          this.langueService.setLangueCouranteSelonRoute(route.snapshot.url[0].path);
      }

    }

}
