import { Component} from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { ActivatedRoute, UrlSegment } from '@angular/router';
import { LangueService } from '../services/langue.service';


@Component({
  selector: 'app-accounting-soft',
  templateUrl: './accounting-soft.component.html',
  styleUrls: ['./accounting-soft.component.css']
})
export class AccountingSoftComponent  {

  constructor(private meta: Meta,private route: ActivatedRoute,private langueService: LangueService ) {
      this.meta.addTags([
       {name: 'description', lang: 'en', content: 'hotel accounting software'},
        {name: 'description', lang: 'fr', content: 'logiciel facturation hotel'},
        {name: 'description', lang: 'es', content: 'programa tpv hosteleria'},
        {name: 'keywords', lang: 'en', content: 'hotel accounting software,hotel billing software,hotel invoice software,small hotel management software,hotel software'},
        {name: 'keywords', lang: 'fr', content: 'logiciel facturation hotel,logiciel gestion hoteliere,logiciel de gestion hoteliere,gestion hoteliere logiciel,logiciel pour hotel,logiciel gestion hotel restaurant,logiciel hotel restaurant,logiciel hotelier'},
        {name: 'keywords', lang: 'es', content: 'programa tpv hosteleria,software de gestion hotelera,sistema de gestion hotelera,software gestion hotelera,programas de gestion hotelera,programa gestion hotelera,tpv tactil hosteleria,software tpv hosteleria'},
        {name: 'charset', content: 'utf-8'},
        {name: 'viewport', content: 'width=device-width, initial-scale=1'},
      ]);

      this.langueService.setLangueCouranteSelonRoute(route.snapshot.url[0].path);
    }

}
