import {BrowserModule, Title} from '@angular/platform-browser';
import {TitleService} from './services/title.service';
import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {HttpClientModule, HttpClient} from '@angular/common/http';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';

import {AppComponent} from './app.component';
import {SponsorshipComponent} from './sponsorship/sponsorship.component';
import {NotFoundComponent} from './not-found/not-found.component';
import {BillingAppComponent} from './billing-app/billing-app.component';
import {AccountingSoftComponent} from './accounting-soft/accounting-soft.component';
import {QuotationComponent} from './quotation/quotation.component';
import {InvoiceComponent} from './invoice/invoice.component';
import {PrivacyPolicyComponent} from './privacy-policy/privacy-policy.component';
import {RecrutementComponent} from './recrutement/recrutement.component';
import {FaqComponent } from './faq/faq.component';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

const appRoutes: Routes = [
  {
    path: 'hotel_billing_app',
    component: BillingAppComponent,
    data: {title: "Hotel Invoice | hotel billing app"}
  },
  {
    path: 'application_android_hotel',
    component: BillingAppComponent,
    data: {title: "Hotel Invoice | application android hotel"}
  },
  {
    path: 'app_para_hotel',
    component: BillingAppComponent,
    data: {title: "Hotel Invoice | app para hotel"}
  },
  {
    path: 'hotel_accounting_software',
    component: AccountingSoftComponent,
    data: {title: "Hotel Invoice | hotel accounting software"}
  },
  {
    path: 'logiciel_facturation_hotel',
    component: AccountingSoftComponent,
    data: {title: "Hotel Invoice | logiciel facturation hotel"}
  },
  {
    path: 'programa_tpv_hosteleria',
    component: AccountingSoftComponent,
    data: {title: "Hotel Invoice | programa tpv hosteleria"}
  },
  {
    path: 'hotel_quotation',
    component: QuotationComponent,
    data: {title: "Hotel Invoice | hotel quotation"}
  },
  {
    path: 'devis_hotel',
    component: QuotationComponent,
    data: {title: "Hotel Invoice | devis hotel"}
  },
  {
    path: 'cotizacion_de_hoteles',
    component: QuotationComponent,
    data: {title: "Hotel Invoice | cotizacion de hoteles"}
  },
  {
    path: 'hotel_invoice',
    component: InvoiceComponent,
    data: {title: "Hotel Invoice | hotel invoice"}
  },
  {
    path: 'facture_hotel',
    component: InvoiceComponent,
    data: {title: "Hotel Invoice | facture hotel"}
  },
  {
    path: 'factura_de_hotel',
    component: InvoiceComponent,
    data: {title: "Hotel Invoice | factura de hotel"}
  },
  {
    path: 'sponsorship',
    component: SponsorshipComponent,
    data: {title: "Hotel Invoice | sponsorship"}
  },
  {
    path: 'parrainage',
    component: SponsorshipComponent,
    data: {title: "Hotel Invoice | parrainage"}
  },
  {
    path: 'patrocinio',
    component: SponsorshipComponent,
    data: {title: "Hotel Invoice | patrocinio"}
  },
  {
    path: 'recruitment',
    component: RecrutementComponent,
    data: {title: "Hotel Invoice | recruitment"}
  },
  {
    path: 'recrutement',
    component: RecrutementComponent,
    data: {title: "Hotel Invoice | recrutement"}
  },
  {
    path: 'reclutamiento',
    component: RecrutementComponent,
    data: {title: "Hotel Invoice | reclutamiento"}
  },
  {
    path: 'privacy_policy',
    component: PrivacyPolicyComponent,
    data: {title: "Hotel Invoice | Privacy Policy"}
  },
  {
    path: 'politique_de_confidentialite',
    component: PrivacyPolicyComponent,
    data: {title: "Hotel Invoice | Politique de confidentialité"}
  }, {
    path: 'politica_de_privacidad',
    component: PrivacyPolicyComponent,
    data: {title: "Hotel Invoice | Política de privacidad"}
  },
  {
    path: 'faq',
    component: FaqComponent,
    data: {title: "Hotel Invoice | FAQ"}
  },
  {
    path: 'questions_frequentes',
    component: FaqComponent,
    data: {title: "Hotel Invoice | Questions fréquentes"}
  }, {
    path: 'preguntas_frecuentes',
    component: FaqComponent,
    data: {title: "Hotel Invoice | Preguntas frecuentes"}
  },
  {
    path: '',
    component: BillingAppComponent,
    data: {title: "Hotel Invoice"}
  },
  {
    path: 'page_not_found',
    component: NotFoundComponent,
    data: {title: "Hotel Invoice | page_not_found Page Titre"}
  },
  {
    path: '**',
    redirectTo: '/page_not_found'
  }
];


@NgModule({
  declarations: [
    AppComponent,
    SponsorshipComponent,
    NotFoundComponent,
    BillingAppComponent,
    AccountingSoftComponent,
    QuotationComponent,
    InvoiceComponent,
    PrivacyPolicyComponent,
    RecrutementComponent,
    FaqComponent
  ],
  imports: [
    RouterModule.forRoot(appRoutes, {scrollPositionRestoration: 'enabled', anchorScrolling: 'enabled'}),
    BrowserModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [Title],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(titleService: TitleService) {
    titleService.init();
  }
}
