import { Component } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { ActivatedRoute, UrlSegment } from '@angular/router';
import { LangueService } from '../services/langue.service';

@Component({
  selector: 'app-recrutement',
  templateUrl: './recrutement.component.html',
  styleUrls: ['./recrutement.component.css']
})
export class RecrutementComponent  {

  constructor(private meta: Meta,private route: ActivatedRoute,private langueService: LangueService ) {
    this.meta.addTags([
      {name: 'description', lang: 'en', content: 'Recruitment Hotel Invoice'},
      {name: 'description', lang: 'fr', content: 'Recrutement Hotel Invoice'},
      {name: 'description', lang: 'es', content: 'Reclutamiento Hotel Invoice'},
      {name: 'keywords', lang: 'en', content: 'Recruitment Hotel Invoice'},
      {name: 'keywords', lang: 'fr', content: 'Recrutement Hotel Invoice'},
      {name: 'keywords', lang: 'es', content: 'Reclutamiento Hotel Invoice'},
      {name: 'charset', content: 'utf-8'},
      {name: 'viewport', content: 'width=device-width, initial-scale=1'},
    ]);

    this.langueService.setLangueCouranteSelonRoute(route.snapshot.url[0].path);
  }


}
