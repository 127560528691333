import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { ActivatedRoute, UrlSegment } from '@angular/router';
import { LangueService } from '../services/langue.service';


@Component({
  selector: 'app-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.css']
})
export class InvoiceComponent {

  constructor(private meta: Meta, private route: ActivatedRoute,private langueService: LangueService ) {
      this.meta.addTags([
       {name: 'description', lang: 'en', content: 'hotel invoice'},
        {name: 'description', lang: 'fr', content: 'facture hotel'},
        {name: 'description', lang: 'es', content: 'factura de hotel'},
        {name: 'keywords', lang: 'en', content: 'hotel invoice'},
        {name: 'keywords', lang: 'fr', content: 'facture hotel,hotel facture'},
        {name: 'keywords', lang: 'es', content: 'factura de hotel'},
        {name: 'charset', content: 'utf-8'},
        {name: 'viewport', content: 'width=device-width, initial-scale=1'},
      ]);

      this.langueService.setLangueCouranteSelonRoute(route.snapshot.url[0].path);
    }

}
