import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class LangueService {

  private translateService: TranslateService;

  constructor(private translate: TranslateService) {
    this.translateService = translate;
  }

  setLangueCouranteSelonRoute(route: string) {

    // Page hotel billing app
    if (route === "hotel_billing_app") {
      this.translateService.use('en');
    }
    else if (route === "application_android_hotel") {
      this.translateService.use('fr');
    }
    else if (route === "app_para_hotel") {
      this.translateService.use('es');
    }

    // Page hotel accounting software
    else if (route === "hotel_accounting_software") {
      this.translateService.use('en');
    }
    else if (route === "logiciel_facturation_hotel") {
      this.translateService.use('fr');
    }
    else if (route === "programa_tpv_hosteleria") {
      this.translateService.use('es');
    }

    // Page hotel quotation
    else if (route === "hotel_quotation") {
      this.translateService.use('en');
    }
    else if (route === "devis_hotel") {
      this.translateService.use('fr');
    }
    else if (route === "cotizacion_de_hoteles") {
      this.translateService.use('es');
    }

    // Page hotel Invoice
    else if (route === "hotel_invoice") {
      this.translateService.use('en');
    }
    else if (route === "facture_hotel") {
      this.translateService.use('fr');
    }
    else if (route === "factura_de_hotel") {
      this.translateService.use('es');
    }


    // Page parrainage
    else if (route === "parrainage") {
      this.translateService.use('fr');
    }
    else if (route === "patrocinio") {
      this.translateService.use('es');
    }
    else if (route === "sponsorship_ar") {
      this.translateService.use('ar');
    }
    else if (route === "sponsorship") {
      this.translateService.use('en');
    }

    // Page recrutement
    else if (route === "recrutement") {
      this.translateService.use('fr');
    }
    else if (route === "reclutamiento") {
      this.translateService.use('es');
    }
    else if (route === "recruitment") {
      this.translateService.use('en');
    }

    //-- Page Politique de confidentialité
    else if (route === "privacy_policy") {
      this.translateService.use('en');
    }
    else if (route === "politique_de_confidentialite") {
      this.translateService.use('fr');
    }
    else if (route === "politica_de_privacidad") {
      this.translateService.use('es');
    }

    //-- Page FAQ
    else if (route === "faq") {
      this.translateService.use('en');
    }
    else if (route === "questions_frequentes") {
      this.translateService.use('fr');
    }
    else if (route === "preguntas_frecuentes") {
      this.translateService.use('es');
    }


    else {
      //-- Par defaut langue de la locale
      this.translateService.use('en');
    }

    //-- Par defaut langue de la locale
  }

}
