import { Component } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { ActivatedRoute, UrlSegment } from '@angular/router';
import { LangueService } from '../services/langue.service';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.css']
})
export class PrivacyPolicyComponent  {

  constructor(private meta: Meta,private route: ActivatedRoute,private langueService: LangueService ) {

    this.meta.addTags([
      {name: 'description', lang: 'en', content: 'Hotel Invoice privacy policy'},
      {name: 'description', lang: 'fr', content: 'Politique de confidentialité Hotel Invoice'},
      {name: 'description', lang: 'es', content: 'Política de privacidad Hotel Invoice'},
      {name: 'keywords', lang: 'en', content: 'privacy policy,Hotel Invoice'},
      {name: 'keywords', lang: 'fr', content: 'politique de confidentialité , Hotel Invoice'},
      {name: 'keywords', lang: 'es', content: 'Política de privacidad,Hotel Invoice'},
      {name: 'charset', content: 'utf-8'},
      {name: 'viewport', content: 'width=device-width, initial-scale=1'},
    ]);

    if (route.snapshot.url[0] && route.snapshot.url[0].path){
      this.langueService.setLangueCouranteSelonRoute(route.snapshot.url[0].path);
    }

  }


}
